import './App.scss';
import * as React from "react";
import axios from "axios";
import { Input, Button } from 'antd';

const { TextArea } = Input;

const App: React.FC = (): JSX.Element => {
  const [words, setWords] = React.useState<string>('');
  const [results, setResults] = React.useState<Array<string>>([]);

  const handleSearch = async () => {
    try {
      let wordsArray = words.split(/\s+/);
      const response = await axios.post('https://zrb-api.opentools.top/api/search', { "words": wordsArray });/* zrb-api.opentools.top localhost*/
      setResults(response.data);
      console.log(response.data)  
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="App">
      <div className="scrachCard">
        <div className="title">日语词汇批量搜索工具 ver.2.0.</div>
        <TextArea rows={4} placeholder='不同词汇使用空格隔开' onChange={(e) => setWords(e.target.value)} />
        <Button type="primary" onClick={handleSearch}>搜索</Button>
      </div>
      <div className="resultsCards">
          {results.map((result) =>
            <div className="resultsCard">
              <div dangerouslySetInnerHTML={{ __html: result }} />
              {/* {result.split("\n").map((item, i) =>
                <p key={i}>{item}</p>
              )} */}
            </div>
          )}
      </div>
    </div>
  );
}

export default App;
